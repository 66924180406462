import { Autocomplete, FormHelperText, InputLabelProps, TextField } from '@mui/material'
import React, { SyntheticEvent } from 'react'
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet'
import useMapLocations, { MapLocation } from '../../hooks/useSearchMapLocations'
import LoadingSpinner from '../UI/LoadingSpinner'
import MapMarketIcon from '../UI/MapMarketIcon'

const DEFAULT_ZOOM = 13
interface LocationPickerProps {
  onCoordinatesChange: (latitude: number, longitude: number) => void
  onAddressChange: (address: string) => void
  startingLocation?: MapLocation
  hasError: boolean
  errorMessage?: string
}

const LocationPicker = ({
  onCoordinatesChange,
  onAddressChange,
  startingLocation,
  hasError,
  errorMessage,
}: LocationPickerProps) => {
  const {
    ref,
    locations,
    setSearch,
    startingLatitude,
    startingLongitude,
    mapLocation,
    setMapLocation,
    getAddressFromCoordinates,
    loading,
  } = useMapLocations({
    startingLocation,
    onAddressChange,
  })

  const MapSelection = () => {
    useMapEvents({
      click(e) {
        setMapLocation((prev) => ({ ...prev, latitude: e.latlng.lat, longitude: e.latlng.lng }))
        getAddressFromCoordinates({ latitude: e.latlng.lat, longitude: e.latlng.lng })

        // parent
        onCoordinatesChange(e.latlng.lat, e.latlng.lng)
      },
    })

    return null
  }

  const handleOptionChange = (event: SyntheticEvent, value: MapLocation | null) => {
    if (value === null) return

    const { latitude, longitude, address = '' } = value
    setMapLocation((prev) => ({ ...prev, latitude, longitude, address }))

    // parent
    onCoordinatesChange(latitude, longitude)
    onAddressChange(address)
  }

  const handleSearchInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null,
    reason: string,
  ) => {
    if (value === null || reason !== 'input') return
    setSearch(value)
  }

  return (
    <React.Fragment>
      <Autocomplete
        sx={{ marginBottom: 1 }}
        fullWidth
        onInputChange={handleSearchInputChange}
        onChange={handleOptionChange}
        onClose={() => {}}
        getOptionLabel={(option) => option?.address || ''}
        getOptionKey={(option) => option.key!}
        options={locations}
        loading={loading}
        value={mapLocation}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Pick the location of your service..."
            InputProps={{
              ...params.InputProps,
              endAdornment: <React.Fragment>{loading && <LoadingSpinner />}</React.Fragment>,
            }}
            InputLabelProps={{
              ...(params.InputLabelProps as Partial<InputLabelProps>),
            }}
          />
        )}
      />
      {hasError && (
        <FormHelperText error={true} sx={{ marginBottom: 1 }}>
          {errorMessage}
        </FormHelperText>
      )}
      <MapContainer
        center={[startingLatitude, startingLongitude]}
        zoom={DEFAULT_ZOOM}
        style={{ height: '400px' }}
        ref={ref}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <MapSelection />
        <Marker position={[mapLocation.latitude, mapLocation.longitude]} icon={MapMarketIcon()} />
      </MapContainer>
    </React.Fragment>
  )
}

export default LocationPicker
