import { Box, Paper, styled } from '@mui/material'

// Container with a grid display, mobile first with 12 columns
export const Container = styled(Paper, { target: 'GuiPractitioner-root' })(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: 'repeat(12, 1fr)',
  overflow: 'clip',
}))

// MediaBox spans 4 columns on mobile and 2 columns on medium screens
export const Media = styled(Box)(({ theme }) => ({
  '--gridColumn': 'span 4',
  gridColumn: 'var(--gridColumn)',
  gridRow: 1,
  position: 'relative',
  overflow: 'clip',

  [theme.breakpoints.up('lg')]: {
    '--gridColumn': 'span 2',
  },

  img: {
    height: '100%',
    objectFit: 'cover',
  },
}))

// Details spans 8 columns on mobile and medium screens
export const Details = styled(Box)(({ theme }) => ({
  '--gridColumn': 'span 12',
  '--gridRow': 2,
  '--paddingInline': theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  gridColumn: 'var(--gridColumn)',
  gridRow: 'var(--gridRow)',
  paddingBlock: theme.spacing(1),
  paddingInline: 'var(--paddingInline)',

  [theme.breakpoints.up('lg')]: {
    '--gridColumn': 'span 6',
    '--paddingRight': 'unset',
    '--gridRow': 1,
    paddingInline: '0 var(--paddingInline)',
  },
}))

// Agenda spans 12 columns on mobile (2nd row), and spans 3 columns on medium screens (1st row)
export const Agenda = styled(Box)(({ theme }) => ({
  '--gridColumn': 'span 8',
  '--gridRow': 1,
  '--paddingInline': theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 8,
  gridColumn: 'var(--gridColumn)',
  gridRow: 'var(--gridRow)',
  justifyContent: 'flex-start',
  paddingBlock: theme.spacing(1),
  paddingRight: 'var(--paddingInline)',

  [theme.breakpoints.up('lg')]: {
    '--gridColumn': 'span 4',
    '--gridRow': 1,
  },
}))
