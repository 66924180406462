import { Alert, Snackbar } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { hideToast, selectToast } from '~/redux/features/toastSlice'
import { useAppSelector } from '~/redux/hooks'

const Toast: React.FC = () => {
  const dispatch = useDispatch()
  const { open, message, severity } = useAppSelector(selectToast)

  const handleClose = () => {
    dispatch(hideToast())
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={26000}
      onClose={handleClose}
      open={open}
    >
      <Alert variant="filled" onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default Toast
