// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { CalendarDialogContextType } from '~/providers/CalendarDialog'

// Define types for better TypeScript support
type Slot = {
  start_time: string
  end_time: string
}

type Unavailability = {
  start_datetime: Date
  end_datetime: Date
}

type Booking = {
  start_time: Date
  end_time: Date
}

export function generateAvailableSlots(
  date: { year?: number; month?: number },
  data: CalendarDialogContextType['state'],
) {
  if (!data) return {}
  // console.log('\n=== Generate Available Slots ===')

  const { duration_minutes, recurring_availability, admin, postId } = data
  const { unavailability, bookings } = admin
  const slotDuration = duration_minutes

  // Process unavailability and bookings
  const unavailabilityByDate = processUnavailability(unavailability)
  const bookingsByDate = processBookings(bookings)

  const datesInMonth = getDatesInMonth({ month: Number(date.month), year: Number(date.year) })

  const availableSlots: Record<string, Slot[]> = {}

  const today = new Date()
  const utcToday = new Date(
    Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()),
  )

  datesInMonth.forEach((date) => {
    const dateStr = date.toISOString().split('T')[0]

    // Skip past dates
    if (date < utcToday) {
      return
    }

    const dayName = date.toLocaleDateString('en-US', { weekday: 'long', timeZone: 'UTC' })

    // Filter recurring availability for the specific day
    const dailyRecurringAvailability = recurring_availability.filter((availability) =>
      availability.days_of_week.includes(dayName),
    )

    if (dailyRecurringAvailability.length === 0) {
      return
    }

    let slots: Slot[] = []

    dailyRecurringAvailability.forEach((availability) => {
      const { start_time, end_time } = availability
      const timeSlots = generateTimeSlots(dateStr, start_time, end_time, slotDuration)
      slots = slots.concat(timeSlots)
    })

    // Exclude unavailability and bookings
    slots = excludeIntervals(
      slots,
      unavailabilityByDate[dateStr] || [],
      bookingsByDate[dateStr] || [],
    )

    // Only add non-empty slots for this date
    if (slots.length) {
      availableSlots[dateStr] = slots
    }
  })

  // console.log({ unavailabilityByDate })
  // console.log({ bookingsByDate })
  // console.log({ datesInMonth })
  // console.log({ today })
  // console.log({ utcToday })
  // console.log({ availableSlots })

  // console.log('===\n')
  return availableSlots
}

function generateTimeSlots(
  dateStr: string,
  startTimeStr: string,
  endTimeStr: string,
  slotDuration: number,
): Slot[] {
  const today = new Date()
  const slots: Slot[] = []
  const startDateTime = new Date(`${dateStr}T${startTimeStr}Z`)
  const endDateTime = new Date(`${dateStr}T${endTimeStr}Z`)
  const currentTime = new Date(startDateTime)

  while (currentTime < endDateTime) {
    const slotStart = new Date(currentTime)
    const slotEnd = new Date(currentTime)
    slotEnd.setUTCMinutes(slotEnd.getUTCMinutes() + slotDuration)

    if (currentTime > today && slotEnd <= endDateTime) {
      slots.push({
        start_time: formatTime(slotStart), // Format the start time
        end_time: formatTime(slotEnd), // Format the end time
      })
    }

    currentTime.setUTCMinutes(currentTime.getUTCMinutes() + slotDuration)
  }

  return slots
}

// Helper function to format time without seconds
function formatTime(date: Date): string {
  const hours = String(date.getUTCHours()).padStart(2, '0') // Ensure two digits
  const minutes = String(date.getUTCMinutes()).padStart(2, '0') // Ensure two digits
  return `${hours}:${minutes}` // Format: HH:mm
}

// Helper function to get all dates in a month
function getDatesInMonth({ year, month }) {
  month = month - 1
  const date = new Date(Date.UTC(year, month, 1))
  const dates = []
  while (date.getUTCMonth() === month) {
    dates.push(new Date(date))
    date.setUTCDate(date.getUTCDate() + 1)
  }

  // console.log({ month, year })
  // console.log({ date })
  // console.log({ dates })
  return dates
}

// Helper function to process unavailability's
export function processUnavailability(data) {
  const unavailabilityByDate: Record<string, any[]> = {}

  data?.forEach((unavailability: Unavailability) => {
    const startDate = new Date(unavailability.start_datetime)
    const endDate = new Date(unavailability.end_datetime)
    const dateStr = startDate.toISOString().split('T')[0]

    if (!unavailabilityByDate[dateStr]) {
      unavailabilityByDate[dateStr] = []
    }

    unavailabilityByDate[dateStr].push({
      start_time: startDate.toISOString().split('T')[1].substring(0, 8),
      end_time: endDate.toISOString().split('T')[1].substring(0, 8),
    })
  })

  return unavailabilityByDate || []
}

// Helper function to process bookings
function processBookings(bookings: Booking) {
  const bookingsByDate = {}

  for (const date in bookings) {
    if (!bookingsByDate[date]) {
      bookingsByDate[date] = []
    }
    bookings[date].forEach((booking) => {
      bookingsByDate[date].push(booking)
    })
  }

  return bookingsByDate
}

// Helper function to exclude intervals (unavailability and bookings)
function excludeIntervals(slots, unavailability, bookings) {
  const intervalsToExclude = unavailability.concat(bookings)

  return slots.filter((slot) => {
    return !intervalsToExclude.some((interval) => {
      return (
        (slot.start_time >= interval.start_time && slot.start_time < interval.end_time) ||
        (slot.end_time > interval.start_time && slot.end_time <= interval.end_time)
      )
    })
  })
}
