import { FC, PropsWithChildren, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import BasicDateCalendar from '~/components/Calendar/BasicDateCalendar'
import CookieConsentFooter from '~/components/Cookies/CookieConsentFooter'
import FooterLayout from '~/components/Layout/footer/FooterLayout'
import HeaderLayout from '~/components/Layout/header/HeaderLayout'
import NavigationDrawer from '~/components/NavigationDrawer'
import Toast from '~/components/Toast'
import useProtectedRoute from '~/hooks/useProtectedRoute'
import { DEFAULT_LANG } from '~/locales'
import { Container } from './RootLayout.styled'

type RootLayoutProps = {
  drawer?: boolean
  isNested?: boolean
  isProtected?: boolean
}

const RootLayout: FC<PropsWithChildren<RootLayoutProps>> = ({
  children,
  drawer = false,
  isProtected = false,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { lang } = useParams()

  useProtectedRoute(isProtected)

  useEffect(() => {
    if (lang && lang !== i18n.language) {
      i18n.changeLanguage(lang)
    } else if (!lang && i18n.language !== DEFAULT_LANG) {
      navigate(`/${i18n.language}${location.pathname}`)
    }
  }, [lang, i18n, location.pathname, navigate])

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <HeaderLayout />

      <BasicDateCalendar />

      <Toast />

      <NavigationDrawer isProtectedPage={isProtected} />

      <Container drawer={drawer}>{children || <Outlet />}</Container>

      <CookieConsentFooter />

      {!isProtected && <FooterLayout />}
    </QueryParamProvider>
  )
}

export default RootLayout
