import {
  Autocomplete,
  CircularProgress,
  InputLabelProps,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { NumberParam, useQueryParams, withDefault } from 'use-query-params'
import Practitioner from '~/components/Practitioner'
import { PrefetchCategories } from '~/components/Practitioner/Practitioner.types'
import { Col, Row } from '~/components/UI/Grid'
import Section from '~/components/UI/Section'
import { usePostFilters } from '~/hooks/usePostFilters'
import useSearchMapLocations, { MapLocation } from '~/hooks/useSearchMapLocations'
import {
  GetCalendarProps,
  useGetCalendarQuery,
  usePrefetchCalendar,
} from '~/redux/features/api/calendarApiAlice'
import {
  PostPractitioners,
  useListPostsQuery,
  usePostPractitionersQuery,
  usePrefetchPosts,
} from '~/redux/features/api/posts-api-slice'
import { selectCalendar, updateCalendarDate } from '~/redux/features/calendar/reducer'
import {
  Category,
  SubCategory,
  useGetCategoriesWithSubCategoriesQuery,
} from '~/redux/features/categories/categories-slice'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'

const Page: FC = () => {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    rowsPerPage: withDefault(NumberParam, 10),
    category: NumberParam,
    subcategory: NumberParam,
    practitioner: NumberParam,
    latitude: withDefault(NumberParam, undefined),
    longitude: withDefault(NumberParam, undefined),
  })
  const { category, subcategory, page, rowsPerPage, practitioner, latitude, longitude } = query

  const [allCategories, setAllCategories] = useState<Category[]>([])
  const [allSubcategories, setAllSubcategories] = useState<SubCategory[]>([])
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null)
  const [selectedSubcategory, setSelectedSubcategory] = useState<SubCategory | null>(null)

  const {
    data: categoriesData,
    isLoading: categoriesAreLoading,
    isSuccess: categoriesIsSuccess,
  } = useGetCategoriesWithSubCategoriesQuery({
    practitioner: practitioner ?? undefined,
  })
  useEffect(() => {
    if (categoriesData && categoriesIsSuccess) {
      const allSubcategories = categoriesData
        .flatMap((category) => category?.subcategories)
        .map(({ ...subcategory }) => subcategory)
      // add a new key with name label and value equal as title key
      const allCategories: Category[] = categoriesData?.reduce<Category[]>(
        (acc, { subcategories, ...category }) => [...acc, category],
        [],
      )
      setAllCategories(allCategories)
      setAllSubcategories(allSubcategories)
    }
  }, [categoriesData, categoriesIsSuccess])
  useEffect(() => {
    if (allCategories) {
      // Update your Autocomplete selection based on the category
      const matchingCategory = allCategories.reduce((acc, { subcategories, ...rest }) => {
        if (rest.id === category) return Object.assign(acc, { ...rest })

        return acc
      }, {} as Category)

      setSelectedCategory({ ...matchingCategory })
    }
  }, [allCategories, category])
  useEffect(() => {
    if (allSubcategories) {
      // Update your Autocomplete selection based on the subcategory
      const matchingSubcategory = allSubcategories
        ?.filter(({ category_id }) => selectedCategory?.id === category_id)
        ?.reduce((acc, { ...rest }) => {
          if (rest.id === subcategory) return Object.assign(acc, { ...rest })
          return acc
        }, {} as SubCategory)
      setSelectedSubcategory(matchingSubcategory)
    }
  }, [allSubcategories, subcategory, selectedCategory])

  const handleCategoryChange = (value: Category | null) => {
    setQuery({ category: value?.id, subcategory: undefined }, 'replaceIn')
  }

  const handleSubcategoryChange = (value: SubCategory | null) => {
    setQuery({ category: value?.category_id, subcategory: value?.id }, 'replaceIn')
  }

  // start: fetch posts data
  const prefetchCategories = usePrefetchPosts('listPosts')
  const handlePrefetchCategories = async ({ type, id }: PrefetchCategories) => {
    if (!type || !id) return void 0
    await prefetchCategories({ [type]: id, page, limit: rowsPerPage })
  }
  const {
    data: dataListPosts,
    isError: isErrorListPosts,
    isLoading: isLoadingListPosts,
  } = useListPostsQuery({
    page,
    limit: rowsPerPage,
    practitioner: practitioner ?? undefined,
    category: category ?? undefined,
    subcategory: subcategory ?? undefined,
    latitude: latitude ?? undefined,
    longitude: longitude ?? undefined,
  })
  const { results = [], count = 0 } = useMemo(() => ({ ...dataListPosts }), [dataListPosts])
  // end: fetch posts data

  // start: location dropdown
  const [, setAddress] = useState<string>()
  const [getFromCoordinatesParams, setGetFromCoordinatesParams] = useState<boolean>(
    Boolean(latitude && longitude),
  )

  const onAddressChange = (address: string) => {
    setAddress(address)
  }

  const {
    locations,
    setLocations,
    setSearch,
    mapLocation,
    getAddressFromCoordinates,
    setMapLocation,
    loading,
  } = useSearchMapLocations({ onAddressChange })

  if (getFromCoordinatesParams) {
    setGetFromCoordinatesParams(false)
    getAddressFromCoordinates({ latitude: Number(latitude), longitude: Number(longitude) })
  }

  const handleSearchInputChange = (value: string | null, reason: string) => {
    if (value === null || reason !== 'input') return
    setSearch(value)
  }

  const handleOptionChange = (value: MapLocation | null) => {
    if (value === null) {
      console.log({ mapLocation })
      setQuery({ latitude: undefined, longitude: undefined }, 'replaceIn')
      setMapLocation((prev) => ({ ...prev, latitude: NaN, longitude: NaN, address: '' }))
      return
    }

    const { latitude, longitude, address = '' } = value
    setMapLocation((prev) => ({ ...prev, latitude, longitude, address }))

    onAddressChange(address)
    setLocations(locations.filter((location) => location.address === address))
    setQuery({ latitude, longitude }, 'replaceIn')
  }
  // end: location dropdown

  // start: practitioner dropdown
  const [selectedPractitioner, setSelectedPractitioner] = useState<PostPractitioners | null>(null)

  const {
    isLoading: practitionersAreLoading,
    data: practitionersData,
    isSuccess: practitionersIsSuccess,
  } = usePostPractitionersQuery({
    category: category ?? undefined,
    subcategory: subcategory ?? undefined,
  })

  useEffect(() => {
    if (practitionersData && practitionersIsSuccess) {
      setSelectedPractitioner(practitionersData.filter(({ id }) => id === practitioner)[0])
    }
  }, [practitioner, practitionersData, practitionersIsSuccess])

  const handlePractitionerChange = (value: PostPractitioners | null) => {
    setQuery({ practitioner: value?.id }, 'replaceIn')
  }
  // start: practitioner dropdown

  const dispatch = useAppDispatch()
  const { date: calendarDate } = useAppSelector(selectCalendar)
  // Use selectFromResult to access cached data if available
  const { refetch: refetchCalendar } = useGetCalendarQuery(calendarDate, {
    skip: calendarDate.postId === undefined,
    refetchOnMountOrArgChange: true,
  })

  const { setFilters, ...postFilters } = usePostFilters()
  // Handle click event to update calendar data
  const handleClick = useCallback(
    (args: GetCalendarProps) => setFilters({ ...postFilters, ...args, open: true }),
    [postFilters],
  )

  const prefetchCalendarAvailability = usePrefetchCalendar('getCalendar')
  const handlePrefetchCalendar = async (args: GetCalendarProps) => {
    await prefetchCalendarAvailability({ ...args, updateState: false })
  }

  useEffect(() => {
    if (postFilters?.open) {
      dispatch(updateCalendarDate({ ...postFilters }))
      refetchCalendar()
    }
  }, [postFilters?.open])

  return (
    <Section size="small" padding={[{ target: 'y', value: 'normal' }]}>
      <Row gap={(theme) => theme.spacing(3)}>
        <Col xs={12} gap={(theme) => theme.spacing(2)} display="flex" justifyContent="space-evenly">
          <Autocomplete
            getOptionLabel={(option) => option?.label || ''}
            isOptionEqualToValue={(option, value) => option?.label === value?.label}
            loading={categoriesAreLoading}
            onChange={(_, value) => handleCategoryChange(value)}
            options={allCategories}
            value={selectedCategory}
            renderInput={(params) => (
              <TextField {...params} label="Select a category" variant="outlined" />
            )}
            disabled={!categoriesIsSuccess || categoriesAreLoading}
            sx={{ flexGrow: 1, minWidth: '200px', maxWidth: '400px' }}
          />
          <Autocomplete
            getOptionLabel={(option) => option?.label || ''}
            isOptionEqualToValue={(option, value) => option?.label === value?.label}
            loading={categoriesAreLoading}
            onChange={(_, value) => handleSubcategoryChange(value)}
            options={
              selectedCategory?.id
                ? allSubcategories?.filter(
                    ({ category_id }) => category_id === selectedCategory?.id,
                  )
                : allSubcategories
            }
            value={selectedSubcategory}
            renderInput={(params) => (
              <TextField {...params} label="Select a subcategory" variant="outlined" />
            )}
            disabled={!categoriesIsSuccess || categoriesAreLoading}
            sx={{ flexGrow: 1, minWidth: '200px', maxWidth: '400px' }}
          />
          <Autocomplete
            options={practitionersData ?? []}
            loading={practitionersAreLoading}
            value={selectedPractitioner}
            getOptionLabel={(option) => option.name}
            onChange={(_, value) => handlePractitionerChange(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Practitioner"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            style={{ flexGrow: 1, minWidth: '200px', maxWidth: '400px' }}
            disabled={!categoriesIsSuccess || categoriesAreLoading}
          />
          <Autocomplete
            fullWidth
            options={locations}
            getOptionLabel={(option) => option?.address ?? ''}
            onInputChange={(_, value, reason) => handleSearchInputChange(value, reason)}
            onChange={(_, value) => handleOptionChange(value)}
            loading={loading}
            value={mapLocation}
            sx={{ flex: 1 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Close to"
                variant="outlined"
                placeholder="Search for a location"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                InputLabelProps={{
                  ...(params.InputLabelProps as Partial<InputLabelProps>),
                }}
              />
            )}
          />
        </Col>

        {!isLoadingListPosts && isErrorListPosts && (
          <Col xs={12}>
            <Typography variant="h4">Something went wrong!!</Typography>
          </Col>
        )}

        {!isErrorListPosts && (
          <>
            <Col xs={12} display="grid" gap={2}>
              {isLoadingListPosts &&
                Array.from({ length: rowsPerPage }).map((_, idx) => (
                  <Practitioner.Skeleton key={idx} />
                ))}

              {!isLoadingListPosts &&
                results?.map(({ id: postId, ...practitioner }) => {
                  const date = new Date(practitioner?.next_available_datetime)
                  const args = {
                    postId,
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate(),
                    hour: `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`,
                  }

                  return (
                    <Practitioner
                      key={postId}
                      {...practitioner}
                      onClick={() => handleClick(args)}
                      prefetchCalendar={() => handlePrefetchCalendar(args)}
                      prefetchCategories={(item) => handlePrefetchCategories(item)}
                      setQuery={setQuery}
                    />
                  )
                })}
            </Col>

            {!isLoadingListPosts && count && count / rowsPerPage > 1 && (
              <Col xs={12}>
                <TablePagination
                  component="div"
                  count={count || 0}
                  onPageChange={(_, page) => setQuery({ page: page + 1 }, 'push')}
                  page={page - 1}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]}
                  sx={{ marginLeft: 'auto' }}
                />
              </Col>
            )}
          </>
        )}
      </Row>
    </Section>
  )
}

export default Page
