import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Prettify } from '~/helpers/types/app'
import { RootState } from '../store'

type ToastState = Prettify<{
  open: boolean
  message: string
  severity: 'error' | 'warning' | 'info' | 'success'
}>

const initialState: ToastState = {
  open: false,
  message: 'This is a toast message',
  severity: 'warning',
}

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<Omit<ToastState, 'open'>>) => {
      state.open = true
      state.message = action.payload.message
      state.severity = action.payload.severity
    },
    hideToast: () => ({ ...initialState }),
  },
})

export const selectToast = (state: RootState) => state.toast

export const { showToast, hideToast } = toastSlice.actions

export default toastSlice
