import {
  Box,
  darken,
  DialogContent as MuiDialogContent,
  Paper as MuiPaper,
  styled,
} from '@mui/material'
import { DayPicker } from 'react-day-picker'

export const Calendar = styled(DayPicker)(({ theme }) => ({
  '.rdp-selected': {
    '.rdp-day_button': {
      '--rdp-selected-border': darken('#d4edda', 0.5),
    },
  },
  '.highlighted > *': {
    color: theme.palette.getContrastText('#d4edda'),
    backgroundColor: '#d4edda',
  },
}))

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  '--border-color': theme.palette.grey[300],
  display: 'flex',
  height: '100%',
  maxWidth: 610,
  padding: 20,

  '> *': {
    flexGrow: 1,
    boxSizing: 'border-box',
  },
}))

export const Paper = styled(MuiPaper)(({ theme }) => ({
  display: 'flex',
  gap: 2,
  boxShadow: 'none',
  border: '1px solid var(--border-color)',
  padding: theme.spacing(2),
}))

export const ScheduleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'stretch',
  gap: 10,
  position: 'absolute',
  overflow: 'auto',
}))

export const ScheduleButton = styled('button')(({ theme }) => ({
  backgroundColor: '#d4edda',
  borderRadius: theme.shape.borderRadiusSmall,
  color: theme.palette.text.primary,
  cursor: 'pointer',
  padding: '5px 10px',

  ':hover': {
    backgroundColor: theme.palette.grey[200],
  },

  '&.active': {
    boxShadow: 'inset 0 0 0 2px var(--swiper-theme-color)',
  },

  ':disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
}))
