import 'leaflet/dist/leaflet.css'
import React from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import 'react-image-crop/dist/ReactCrop.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import i18n from './i18n'
import Providers from './providers'
import reportWebVitals from './reportWebVitals'
import router from './routes'

const browserRouter = createBrowserRouter(router)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Providers>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={browserRouter} />
      </I18nextProvider>
    </Providers>
  </React.StrictMode>,
)

reportWebVitals()
