import { Box, Button, Chip } from '@mui/material'
import MuiSkeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Image from '~/components/Image'
import { selectAuth } from '~/redux/features/auth/auth-slice'
import { useAppSelector } from '~/redux/hooks'
import { Agenda, Container, Details, Media } from './Practitioner.styled'
import { PractitionerProps } from './Practitioner.types'

// Usage in a component
const Practitioner: FC<PractitionerProps> = ({
  id,
  health_practitioner,
  agenda,
  description,
  details,
  media,
  onClick,
  prefetchCalendar,
  prefetchCategories,
  next_available_datetime,
  category,
  subcategory,
  setQuery,
  ...props
}) => {
  const { i18n, t } = useTranslation()
  const { authenticated, ...user } = useAppSelector(selectAuth)
  const isDoctor = user?.account_detail?.account_type === 'doctor'

  media = { src: props?.thumbnail, alt: props?.title }

  return (
    <>
      {(description || details) && (
        <Container elevation={3} onMouseEnter={prefetchCalendar}>
          <Media>
            <Image {...media} />
          </Media>

          <Details>
            <Typography variant="h5">{health_practitioner}</Typography>

            <Typography>{description || details}</Typography>

            <Box display="flex" gap={1} mt="auto">
              <Chip
                label={category?.title}
                onMouseEnter={() =>
                  category?.id && prefetchCategories?.({ type: 'category', id: category.id })
                }
                onClick={() =>
                  setQuery?.({ category: category?.id, subcategory: undefined }, 'replaceIn')
                }
              />

              <Chip
                label={subcategory?.title}
                onMouseEnter={() =>
                  subcategory?.id &&
                  prefetchCategories?.({ type: 'subcategory', id: subcategory.id })
                }
                onClick={() =>
                  setQuery?.({ category: category?.id, subcategory: subcategory?.id }, 'replaceIn')
                }
              />
            </Box>
          </Details>

          <Agenda>
            <Typography variant="h5">{t('availability', 'Availability')}</Typography>

            {next_available_datetime && (
              <Typography>{formatDate(next_available_datetime, i18n.language)}</Typography>
            )}

            <Button
              fullWidth
              disableElevation
              variant="contained"
              sx={{ mt: 'auto' }}
              onClick={onClick}
              disabled={isDoctor}
            >
              {t('book', 'Book')}
            </Button>
          </Agenda>
        </Container>
      )}
    </>
  )
}

const Skeleton: FC = () => (
  <Container elevation={3}>
    <Media>
      <MuiSkeleton variant="rectangular" width="stretch" height="100%"></MuiSkeleton>
    </Media>

    <Details>
      <MuiSkeleton variant="text" width="stretch">
        <Typography variant="h4">.</Typography>
      </MuiSkeleton>

      <MuiSkeleton variant="text" width="stretch">
        <Typography>.</Typography>
      </MuiSkeleton>
      <MuiSkeleton variant="text" width="stretch">
        <Typography>.</Typography>
      </MuiSkeleton>
      <MuiSkeleton variant="text" width="stretch">
        <Typography>.</Typography>
      </MuiSkeleton>
    </Details>

    <Agenda as={Box}>
      <MuiSkeleton variant="text" width="stretch">
        <Typography variant="h4">.</Typography>
      </MuiSkeleton>

      <MuiSkeleton variant="text" width="stretch">
        <Typography>.</Typography>
      </MuiSkeleton>
    </Agenda>
  </Container>
)

export default Object.assign(Practitioner, { Skeleton })

function formatDate(dateString: string, locale = 'en-UK') {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }

  return new Intl.DateTimeFormat(locale, options).format(date)
}
