import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { apiSlice } from './features/api/api-slice'
import authMiddleware from './features/auth/auth-middleware'
import authSlice from './features/auth/auth-slice'
import calendarSlice from './features/calendar/reducer'
import cookiesMiddleware from './features/cookies/cookies-middleware'
import cookiesSlice from './features/cookies/cookies-slice'
import navigationSlice from './features/navigation/navigation-slice'
import toastSlice from './features/toastSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    calendar: calendarSlice.reducer,
    cookies: cookiesSlice.reducer,
    navigation: navigationSlice.reducer,
    toast: toastSlice.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({}).concat([apiSlice.middleware, cookiesMiddleware, authMiddleware])
  },
  devTools: process.env.NODE_ENV !== 'production',
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
