import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export interface UsePostFilters {
  day?: number
  hour?: string
  month?: number
  open?: boolean
  postId?: number
  year?: number
}

export function usePostFilters() {
  const [searchParams, setSearchParams] = useSearchParams()

  const open = searchParams.get('open') as unknown as UsePostFilters['open']
  const postId = searchParams.get('postId') ? Number(searchParams.get('postId')) : undefined
  const hour = searchParams.get('hour') as UsePostFilters['hour']
  const day = searchParams.get('day') ? Number(searchParams.get('day')) : new Date().getDate()
  const month = searchParams.get('month')
    ? Number(searchParams.get('month'))
    : new Date().getMonth() + 1
  const year = searchParams.get('year')
    ? Number(searchParams.get('year'))
    : new Date().getFullYear()

  const setFilters = useCallback(
    (filters: UsePostFilters) => {
      setSearchParams((params) => {
        if (filters.open) {
          params.set('open', filters.open.toString())
        }

        if (filters.postId) {
          params.set('postId', filters.postId.toString())
        }

        if (filters.hour) {
          params.set('hour', filters.hour.toString())
        }

        if (filters.day) {
          params.set('day', filters.day.toString())
        }

        if (filters.month) {
          params.set('month', filters.month.toString())
        }

        if (filters.year) {
          params.set('year', filters.year.toString())
        }

        if (filters.open?.toString() === 'false') {
          params.delete('open')
          params.delete('postId')
          params.delete('hour')
          params.delete('day')
          params.delete('month')
          params.delete('year')
        }

        return params
      })
    },
    [setSearchParams],
  )

  return { day, hour, month, open, postId, setFilters, year }
}
