import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '~/redux/store'

export type CalendarData = {
  postId: number
  duration_minutes: number
  admin: {
    id: number
    name: string
    account_type: string
    unavailabilities: Array<{
      start_datetime: Date
      end_datetime: Date
    }>
    bookings: Record<
      string,
      Array<{
        start_time: Date
        end_time: Date
      }>
    >
  }
  recurring_availability: Array<{
    id: number
    start_time: string
    end_time: string
    days_of_week: Array<'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday'>
  }>
}

export type CalendarDate = {
  postId?: number
  month?: number
  year?: number
}

export type CalendarProps = {
  isLoading?: boolean
  data: CalendarData
  open: boolean
  date: CalendarDate
}

const initialState: CalendarProps = {
  isLoading: false,
  open: false,
  data: {
    postId: 0,
    duration_minutes: 0,
    admin: {
      id: 0,
      name: '',
      account_type: '',
      unavailabilities: [],
      bookings: {},
    },
    recurring_availability: [],
  },
  date: { postId: NaN, month: NaN, year: NaN },
}

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    date: (state, { payload }: PayloadAction<CalendarDate>) => ({
      ...state,
      date: payload,
    }),
    data: (state, { payload }: PayloadAction<CalendarData>) => ({
      ...state,
      data: payload,
    }),
    open: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      open: payload,
    }),
    loading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    reset: () => initialState,
  },
})

export const {
  data: updateCalendarData,
  date: updateCalendarDate,
  loading: updateCalendarLoading,
  open: updateCalendarOpen,
  reset: resetCalendar,
} = calendarSlice.actions

export const selectCalendar = (state: RootState) => state.calendar

export default calendarSlice
