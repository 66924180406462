import { Dayjs } from 'dayjs'
import { Booking } from '~/helpers/types/booking'
import { urls } from '~/helpers/utils/urls'
import { apiSlice } from './api-slice'
import { managementApiSlice } from './management-api-slice'

interface GetBookingInput {
  bookingId: number
}

export interface GivenEvent extends Event {
  id: number
  title: string
  price: number
  unit: string
  start: Date
  end: Date
  location: string
  client_name: string
  status: 'in_progress' | 'completed' | 'canceled' | 'accepted'
}

export const bookingApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['Bookings'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBooking: builder.query<Booking, GetBookingInput>({
        query: (args) => {
          const { bookingId } = args
          return `/booking/${bookingId}`
        },
        providesTags: (result, error, { bookingId }) => [{ type: 'Bookings', id: bookingId }],
      }),
      listProviders: builder.query<any, { postId: number }>({
        query: (args) => {
          const { postId } = args
          return `/booking/posts/${postId}/providers`
        },
      }),
      getBlockedSlots: builder.query<any, { postId: number; startDate: string; endDate: string }>({
        query: (args) => {
          const { postId, startDate, endDate } = args
          return `/booking/blocked-slots/${postId}${urls.argsToParams({
            startDate,
            endDate,
          })}`
        },
        providesTags: (result) => [{ type: 'Bookings', id: 'BLOCKED_SLOTS' }],
      }),
      getBlockedProviderSlots: builder.query<GivenEvent[], { startDate: string; endDate: string }>({
        query: (args) => {
          const { startDate, endDate } = args
          return `/booking/list/${urls.argsToParams({
            startDate,
            endDate,
          })}`
        },
        transformResponse: (response: GivenEvent[]) => {
          return response.map((event) => ({
            ...event,
            start: new Date(event.start),
            end: new Date(event.end),
          }))
        },
        providesTags: (result) => [{ type: 'Bookings', id: 'BLOCKED_SLOTS' }],
      }),
      updateBookingStatus: builder.mutation<
        any,
        {
          bookingId: number
          status: string
        }
      >({
        query: ({ bookingId, status }) => ({
          url: `/booking/${bookingId}/update`,
          method: 'PATCH',
          body: {
            status,
          },
        }),
        invalidatesTags: [{ type: 'Bookings', id: 'BLOCKED_SLOTS' }],
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled
            dispatch(managementApiSlice.util.invalidateTags(['ManagementPostsMetric']))
          } catch (error) {
            console.error('Failed to update user:', error)
          }
        },
      }),
      createBooking: builder.mutation<
        any,
        {
          post: number
          provider?: number
          startDatetime?: Dayjs
          endDateTime?: Dayjs
          date?: string
          start_time?: string
        }
      >({
        query: ({ startDatetime, endDateTime, ...booking }) => {
          return {
            url: '/booking/new',
            method: 'POST',
            body: {
              ...booking,
              ...(startDatetime && { start_datetime: startDatetime?.toISOString() }),
              ...(endDateTime && { end_datetime: endDateTime?.toISOString() }),
            },
          }
        },
        invalidatesTags: [{ type: 'Bookings', id: 'BLOCKED_SLOTS' }],
      }),
    }),
  })

export const {
  useListProvidersQuery,
  useGetBlockedSlotsQuery,
  useCreateBookingMutation,
  useGetBookingQuery,
  useGetBlockedProviderSlotsQuery,
  useUpdateBookingStatusMutation,
} = bookingApiSlice

export type BookingFormDataValues = {
  post: string
  provider: string
  startDatetime: Dayjs
}
