import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '~/redux/store'
import { getCachedAuth } from './auth-actions'

export interface AuthState {
  authenticated?: boolean
  access: string | unknown
  refresh: string | unknown
  account_detail?: {
    id: number
    account_type?: 'doctor' | 'patient'
    avatar: string
    certificate?: string
    email_verified: boolean
    email: string
    first_name: string
    last_name: string
    settings?: {
      language: string
    }
  }
}

const cachedAuth = getCachedAuth()
const initialState = {
  authenticated: Boolean(cachedAuth.access && cachedAuth.refresh),
  access: cachedAuth.access,
  refresh: cachedAuth.refresh,
  account_detail: cachedAuth.account_detail,
} as AuthState

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<AuthState>) => ({
      ...state,
      ...action.payload,
      authenticated: Boolean(action.payload.access && action.payload.refresh),
    }),
    reset: () => ({
      access: undefined,
      account_detail: undefined,
      authenticated: false,
      refresh: undefined,
    }),
  },
})

export default authSlice

export const authActions = authSlice.actions

export const selectAuth = (state: RootState) => state.auth
