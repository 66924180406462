import button from './button'
import calendar from './calendar'
import cookies from './cookies'
import form from './form'
import locale from './locales'
import myProfile from './myProfile'
import navigation from './navigation'
import newsletter from './newsletter'
import testimonials from './testimonials'

const ui = {
  button,
  cookies,
  forgotPassword: 'Mot de passe oublié',
  dontHaveAccount: "Vous n'avez pas de compte ?",
  findUs: 'Trouvez-nous ici',
  form,
  locale,
  management: { clients: 'Clients' },
  myProfile,
  navigation,
  newsletter,
  calendar,
  testimonials,
}

export default ui
