import { CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { FC, PropsWithChildren } from 'react'
import { Provider } from 'react-redux'
import { NotificationProvider } from '~/components/UI/NotificationProvider'
import { store } from '~/redux/store'
import GlobalStyles from '~/themes/GlobalStyles'
import NavigationDrawerProvider from './NavigationDrawer'
import ThemeProvider from './ThemeProvider/themeProvider'

type ProvidersProps = PropsWithChildren

const Providers: FC<PropsWithChildren<ProvidersProps>> = ({ children }) => {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <NavigationDrawerProvider>
          <NotificationProvider>
            <ThemeProvider>
              <CssBaseline />
              <GlobalStyles />

              {children}
            </ThemeProvider>
          </NotificationProvider>
        </NavigationDrawerProvider>
      </LocalizationProvider>
    </Provider>
  )
}

export default Providers
