import { apiSlice } from '../api/api-slice'

interface CategoryBase {
  id: number
  title?: string
  label?: string
}

export interface SubCategory extends CategoryBase {
  category?: CategoryBase
  category_id?: number
}

export interface Category extends CategoryBase {
  subcategories?: SubCategory[]
}

export const categoriesApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['Categories'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCategoriesWithSubCategories: builder.query<Category[], { practitioner?: number }>({
        query: (args) => ({
          url: `/category/categories-with-subcategories`,
          params: { ...args },
        }),
        transformResponse: (response: Category[]) => {
          const transformedData: Category[] = response.map((item) => ({
            id: item.id,
            label: item.title,
            subcategories: item?.subcategories?.map(
              ({ title: label, category, ...subcategory }) => ({
                ...subcategory,
                label,
                category_id: item.id,
              }),
            ),
          }))

          return transformedData
        },
      }),
    }),
  })

export const { useGetCategoriesWithSubCategoriesQuery } = categoriesApiSlice
